import React from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import SEO from '../components/seo'
import { Container } from '../styled'

const ErrorContainer = styled(Container)`
  padding: 60px 30px;
  text-align: center;

  h2 {
    font-family: quasimoda-2, quasimoda, sans-serif;
    font-weight: bold;
    font-size: 60px;
  }
`

const NotFoundPage = ({ path }: any) => {
  const { t } = useTranslation()
  return (
    <ErrorContainer>
      <SEO title={t('404.notFound')} path={path} />
      <h2>{t('404.oops')}</h2>
      <p>{t('404.doesntExist')}</p>
    </ErrorContainer>
  )
}

export default NotFoundPage
